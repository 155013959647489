import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule, TranslateStore} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TableVirtualScrollModule} from "ng-table-virtual-scroll";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {CustomMatPaginatorIntl} from "./cynteka-desktop/common/custom-mat-paginator-intl/custom-mat-paginator-intl";
import {WelcomePopupContentComponent} from './welcome-popup-content/welcome-popup-content.component';
import {MatTabsModule} from "@angular/material/tabs";
import {AsyncPipe} from "@angular/common";
import {MatDialogModule} from "@angular/material/dialog";
import {MessagesService} from "./common/services/messages.service";
import {ScriptService} from "./common/services/script.service";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  // return new TranslateHttpLoader(http,'../assets/locale/', '.json')
  return new TranslateHttpLoader(http, './assets/spa2/assets/locale/');
}

@NgModule({
    declarations: [
        AppComponent,
        WelcomePopupContentComponent
    ],
    imports: [
        TableVirtualScrollModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
          defaultLanguage: 'ru',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatTabsModule,
        MatDialogModule,
    ],
    providers: [
        TranslateStore,
        {
        provide: MatPaginatorIntl,
        useClass: CustomMatPaginatorIntl,
        },
        AsyncPipe,
        MessagesService,
        ScriptService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
